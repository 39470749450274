import img_1 from "../../assets/img/img_1.jpg";
import img_2 from "../../assets/img/img_2.jpg";
import img_3 from "../../assets/img/img_3.jpg";
import img_4 from "../../assets/img/img_4.jpg";
import img_5 from "../../assets/img/img_5.jpg";
import img_6 from "../../assets/img/img_6.jpg";
import img_7 from "../../assets/img/img_7.jpg";
import img_8 from "../../assets/img/img_8.jpg";
import img_9 from "../../assets/img/img_9.jpg";
import img_10 from "../../assets/img/img_10.jpg";
import img_11 from "../../assets/img/img_11.jpg";
import img_12 from "../../assets/img/img_12.jpg";
import img_13 from "../../assets/img/img_13.jpg";
import img_14 from "../../assets/img/img_14.jpg";

const ProductData = [
    {
        id: 1,
        category: "Saddle",
        img: img_1,
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:true,

    },
    {
        id: 2,
        category: "Saddle Pad",
        img: img_2,
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:false,

    },
    {
        id: 3,
        category: "Seat Saver",
        img: img_3,
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:true,

    },
    {
        id: 4,
        category: "Stirrup",
        img: img_4,
        altImg: "No Img Found",
        title: "Riding apparel and footwear",
        isNewArrival:true,

    },
    {
        id: 5,
        category:"Bridle",
        img: img_5,
        altImg: "No Img Found",
        title: "Whips and Spurs",
        isNewArrival:true,

    },
    {
        id: 6,
        category:"Martingales",
        img: img_6,
        altImg: "No Img Found",
        title: "Bridles and reins",
        isNewArrival:true,

    },
    {
        id: 7,
        category:"Saddle Girth",
        img: img_7,
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:true,

    },
    {
        id: 8,
        category:"Halter",
        img: img_8,
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:true,
    },
    {
        id: 9,
        category:"Lead Rope",
        img: img_8,
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:true,
    },
    {
        id: 10,
        category:"Lunging Equipment",
        img: img_10,
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:true,
    },
    {
        id: 11,
        category:"Horse Boots",
        img: img_11,
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:true,
    },
    {
        id: 12,
        category:"Helmet",
        img: img_12,
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:true,
    },
    {
        id: 13,
        category:"Stable Rugs",
        img: img_13,
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:true,
    },
    {
        id: 14,
        category:"Stable Equipment",
        img: img_14,
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:true,
    },
    {
        id: 15,
        category:"Grooming Equipment",
        img: img_9,
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:true,
    },
    {
        id: 16,
        category:"Gloves",
        img: img_8,
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:true,
    },
    {
        id:17,
        category:"Riding Boots",
        img: img_8,
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:true,
    },
    {
        id:18,
        category:"Long Boots",
        img: img_8,
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:true,
    },
    {
        id:19,
        category:"Riding Breeches",
        img: img_8,
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:true,
    },
    {
        id:20,
        category:"Chaps",
        img: img_8,
        altImg: "No Img Found",
        title: "Product Name required",
        isNewArrival:true,
    },

]

export default ProductData;