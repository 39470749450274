import React from 'react'

function PrivacyPolicy() {
  return (
    <div className="p-2 flex justify-center">
        <p className="font-bold text-2xl">Privacy Policy</p>
      </div>
  )
}

export default PrivacyPolicy